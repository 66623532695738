
<template>
  <section class="text-center component-loading">
    <div class="loading mx-auto"></div>
    <div class="mt-5 mb-4">
{{ text }}
</div>
    <div>Please do not close this window</div>
  </section>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    }
  }
};
</script>
