import { createApp, defineAsyncComponent } from 'vue'
import FloatingVue from 'floating-vue' // replacement of v-tooltip. already removed and replaced in package.json 
import store from "@/store/store.js";
import loaderOverlay from "./components/LoaderOverlay.vue";
import baseLoadingComponent from "./components/BaseLoadingComponent.vue";
const registrationComponent = defineAsyncComponent(() => import("./Registration.vue"));
const donationComponent = defineAsyncComponent(() => import("./Donation.vue"));
const sponsorshipComponent = defineAsyncComponent(() => import("./Sponsorship.vue"));
const renewSponsorship = defineAsyncComponent(() => import("./RenewSponsorship.vue"));
const loginComponent = defineAsyncComponent(() => import("./Login.vue"));
const forgotComponent = defineAsyncComponent(() => import("./forgotComponent.vue"));
const resetPassword = defineAsyncComponent(() => import("./ResetPassword.vue"));
const yourStudentsSummary = defineAsyncComponent(() => import("./YourStudentsSummary.vue"));
const notificationsComponent = defineAsyncComponent(() => import("./Notifications.vue"));
const yourGivingSummary = defineAsyncComponent(() => import("./YourGivingSummary.vue"));
const yourGivingChart = defineAsyncComponent(() => import("./YourGivingChart.vue"));
const supporterProfile = defineAsyncComponent(() => import("./SupporterProfile.vue"));
const studentFileGallery = defineAsyncComponent(() => import("./StudentFileGallery.vue"));
const correspondenceHistory = defineAsyncComponent(() => import("./CorrespondenceHistory.vue"));
const changePassword = defineAsyncComponent(() => import("./ChangePassword.vue"));
const commsPref = defineAsyncComponent(() => import("./CommsPref.vue"));
const supporterDonationsHistory = defineAsyncComponent(() => import("./SupporterDonationsHistory.vue"));
const paymentDetails = defineAsyncComponent(() => import("./PaymentDetails.vue"));
const spinner = defineAsyncComponent(() => import("./components/Spinner.vue"));
const carousel = defineAsyncComponent(() => import("./components/Carousel.vue"));
const stories = defineAsyncComponent(() => import("./components/MediaStories.vue"));
const quickDonate = defineAsyncComponent(() => import("./components/donation/QuickDonation.vue"));
const supporterSponsoredStudentMessage = defineAsyncComponent(() => import("./SupporterSponsoredStudentMessage.vue"));
const donationConfirmation = defineAsyncComponent(() => import("./DonationConfirmation.vue"));
const currentRegularPayments = defineAsyncComponent(() => import("./CurrentRegularPayments.vue"));
const increaseRegularPayments = defineAsyncComponent(() => import("./IncreaseRegularPayments.vue"));
const sponsorshipUpsell = defineAsyncComponent(() => import("./sponsorshipUpsell.vue"));
const SponsorshipProgressBar = defineAsyncComponent(() => import("./components/SponsorshipProgressBar.vue"));




//TEMPORARY - unitl Tailwind UI goes live...
import "bootstrap/js/dist/dropdown";

let app = Object;

// the initial tag for these components comes from the html markup.
// this allows the backend to pass initial paramaters easily
let dynamicComponentMap = {
  "spinner-vue-component": [
    {
      tagName: "spinner",
      component: spinner,
    },
  ],
  "login-vue-component": [
    {
      tagName: "logincomponent",
      component: loginComponent,
    },
  ],
  "registration-vue-component": [
    {
      tagName: "registrationcomponent",
      component: registrationComponent,
    },
  ],
  "resetpassword-vue-component": [
    {
      tagName: "resetpassword",
      component: resetPassword,
    },
  ],
  "changepassword-vue-component": [
    {
      tagName: "changepassword",
      component: changePassword,
    },
  ],
  "donation-vue-component": [
    {
      tagName: "donation",
      component: donationComponent,
    },
  ],
  "donation-confirmation-vue-component": [
    {
      tagName: "donationconfirmation",
      component: donationConfirmation,
    },
  ],
  "sponsorship-vue-component": [
    {
      tagName: "sponsorship",
      component: sponsorshipComponent,
    },
  ],
  "renewsponsorship-vue-component": [
    {
      tagName: "renewsponsorship",
      component: renewSponsorship,
    },
  ],
  "notifications-vue-component": [
    {
      tagName: "notificationscomponent",
      component: notificationsComponent,
    },
  ],
  "yourgivingsummary-vue-component": [
    {
      tagName: "yourgivingsummary",
      component: yourGivingSummary,
    },
  ],
  "yourgivingchart-vue-component": [
    {
      tagName: "yourgivingchart",
      component: yourGivingChart,
    },
  ],
  "yourstudentssummary-vue-component": [
    {
      tagName: "yourstudentssummary",
      component: yourStudentsSummary,
    },
  ],
  "editsupporterprofile-vue-component": [
    {
      tagName: "supporterprofile",
      component: supporterProfile,
    },
  ],
  "commspref-vue-component": [
    {
      tagName: "commspref",
      component: commsPref,
    },
  ],
  "correspondencehistory-vue-component": [
    {
      tagName: "correspondencehistory",
      component: correspondenceHistory,
    },
  ],
  "studentfilegallery-vue-component": [
    {
      tagName: "studentfilegallery",
      component: studentFileGallery,
    },
  ], 
  "supporterdonationshistory-vue-component": [
    {
      tagName: "supporterdonationshistory",
      component: supporterDonationsHistory,
    }
  ],
  "supportersponsoredstudentmessage-vue-component": [
    {
    tagName: "supportersponsoredstudentmessage",
    component: supporterSponsoredStudentMessage,
    }
  ],
  "paymentdetails-vue-component": [
    {
      tagName: "paymentdetails",
      component: paymentDetails,
    },
  ],
  "currentRegularPayments-vue-component": [
    {
      tagName: "currentregularpayments",
      component: currentRegularPayments,
    },
  ],
  "increaseRegularPayments-vue-component": [
    {
      tagName: "increaseregularpayments",
      component: increaseRegularPayments,
    },
  ],
  "sponsorshipUpsell-vue-component": [
    {
      tagName: "sponsorshipupsell",
      component: sponsorshipUpsell,
    },
  ],
  "sponsorshipProgressBar-vue-component": [
    {
      tagName: "sponsorshipprogressbar",
      component: SponsorshipProgressBar,
    },
  ],
  "carousel-vue-component": [
    {
      tagName: "carousel",
      component: carousel,
    },
  ],
  "stories-vue-component": [
    {
      tagName: "stories",
      component: stories,
    },
  ],
  "quickdonate-vue-component": [
    {
      tagName: "quickdonate",
      component: quickDonate,
    },
  ],  
  "forgotcomponent-vue-component": [
    {
      tagName: "forgotcomponent",
      component: forgotComponent,
    },
  ],  
};

var possibleAppContainers = document.querySelectorAll("#portal > div, .vue-component");

if (possibleAppContainers) {
  for (var i = 0; i < possibleAppContainers.length; i++) {
    let appContainer = possibleAppContainers[i].id;
    if (appContainer) {
      let componentDelimeter = '--';//This delimeter enables components to be reused on same page without id clash
      let appContainerPrefix = appContainer.split(componentDelimeter)[0];
      let dynamicComponent = dynamicComponentMap[appContainerPrefix];
      if (dynamicComponent) {
        for (var j = 0; j < dynamicComponent.length; j++) {
          app = createApp({});
          app.use(store);
          app.use(FloatingVue);
          app.component(
            dynamicComponent[j].tagName,
            dynamicComponent[j].component
          );
          app.component("loaderOverlay", loaderOverlay); 
          app.component("baseLoadingComponent", baseLoadingComponent);
        }
        app.mount('#'+ appContainer);
      }
    }
  }
}
