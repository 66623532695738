<template>
  <div class="position-fixed" style="left:0px; z-index: 1999;">
    <div class="vue-loader-overlay" :class="{ 'is-active': getLoading }">
      <div class="vue-loader-overlay-content col-12 text-center">
        <img class="mb-3" :src="getLoadingImage" alt="The Smith Family">
        <h2 v-if="getLoadingHeader" class="mb-4">
          {{ getLoadingHeader }}
        </h2>
        <p v-if="getLoadingMessage" class="statement" v-html="getLoadingMessage">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  name: "LoaderOverlay",
  computed: {
    ...mapGetters([
      "getLoading",
      "getLoadingHeader",
      "getLoadingMessage",
      "getLoadingImage"
    ])
  }
};
</script>