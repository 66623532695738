export const state = {
  apiLoading: false,
  apiDefaultLoadingHeader: "Loading",
  apiDefaultLoadingMessage: "",
  apiDefaultLoadingImage: "/-/media/site/global/the-smith-family-logo-loader.svg",
  apiCurrentLoadingHeader: "",
  apiCurrentLoadingMessage: "",
  apiCurrentLoadingImage: ""
};

export const mutations = {
  SET_LOADING(state, status) {
    state.apiLoading = status;
  },
  SET_LOADING_HEADER(state, info) {
    state.apiCurrentLoadingHeader = info;
  },
  SET_LOADING_MESSAGE(state, info) {
    state.apiCurrentLoadingMessage = info;
  },
  SET_LOADING_IMAGE(state, info) {
    state.apiCurrentLoadingImage = info;
  }
};

export const actions = {
  setLoading({ commit, dispatch }, status) {
    commit("SET_LOADING", status);
    if(status === false) {
      dispatch("setLoadingHeader", state.apiDefaultLoadingHeader);
      dispatch("setLoadingMessage", state.apiDefaultLoadingMessage);
      dispatch("setLoadingImage", state.apiDefaultLoadingImage);
    }
  },
  setLoadingHeader({ commit }, info) {
    commit("SET_LOADING_HEADER", info);
  },
  setLoadingMessage({ commit }, info) {
    commit("SET_LOADING_MESSAGE", info);
  },
  setLoadingImage({ commit }, info) {
    commit("SET_LOADING_IMAGE", info);
  }
};

export const getters = {
  getLoading: state => {
    return state.apiLoading;
  },
  getLoadingHeader: state => {
    return state.apiCurrentLoadingHeader
      ? state.apiCurrentLoadingHeader
      : state.apiDefaultLoadingHeader;
  },
  getLoadingMessage: state => {
    return state.apiCurrentLoadingMessage
      ? state.apiCurrentLoadingMessage
      : state.apiDefaultLoadingMessage;
  },
  getLoadingImage: state => {
    return state.apiCurrentLoadingImage
      ? state.apiCurrentLoadingImage
      : state.apiDefaultLoadingImage;
  }
};
